<!--
 * @Date: 2022-03-16 14:13:51
 * @LastEditTime: 2022-03-23 18:06:53
 * @FilePath: \hc_website\src\views\homePage\technicalCapability\BIMlightweightVisualization\BIMlightweightVisualization.vue
-->
<template>
  <div class="cityZation" ref="cityZation">
    <video class="profile" controls ref="videoa" @play="playVideo">
      <source :src="videoResource" type="video/mp4" />
    </video>
    <div class="cityRight">
      <div class="visualization">
        <div class="contentleft">大</div>
        <div class="contentright">支持百万级模型数据可视化</div>
      </div>
      <div class="lint"></div>
      <div class="visualization">
        <div class="contentleft">多</div>
        <div class="contentright">满足多种数据分类体系</div>
      </div>
      <div class="lint"></div>
      <div class="visualization">
        <div class="contentleft">美</div>
        <div class="contentright">采用三维可视化渲染手段</div>
      </div>
      <div class="lint"></div>
      <div class="visualization">
        <div class="contentleft">简</div>
        <div class="contentright">开放API接口机制</div>
      </div>
    </div>
  </div>
</template>
<script>
import ipconfig from "/public/ipconfig.js";
export default {
  data() {
    return {
      videoResource: ipconfig.ip + "/technicalCapability/home4.mp4",
      isCloseVideo: true,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    /**
     * @function handleScroll 滚动的事件 & 获取滚动的高度
     */
    handleScroll() {
      let scrollY = document.documentElement.scrollTop;
      let dom = this.$refs.cityZation;
      if (!dom) return;
      let elementTop = this.getElementTop(dom);
      if (
        scrollY > elementTop + dom.clientHeight ||
        scrollY < elementTop - dom.clientHeight
      ) {
        if (this.isCloseVideo) {
          this.$refs["videoa"].pause();
          this.isCloseVide = false;
        }
      }
    },
    /**
     * @function playVideo 播放video
     */
    playVideo() {
      this.isCloseVideo = true;
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll, true);
  },
};
</script>
<style lang="scss" scoped>
.cityZation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .profile {
    width: 48%;
    height: 100%;
  }
  .cityRight {
    width: 48%;
    .visualization {
      display: flex;
      justify-content: flex-start;
      // align-items: center;

      .contentleft {
        font-size: 32px;
        color: #bc1e1d;
        margin-right: 20px;
      }
      .contentright {
        text-align: left;
        margin-top: 9px;
        font-size: 24px;
        color: #14171c;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // white-space: nowrap;
      }
    }
    .lint {
      width: 40px;
      height: 4px;
      background-color: #bc1e1d;
      margin: 25px 0;
    }
  }
}
</style>
